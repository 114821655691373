import classes from './AboutContent.module.css';

import { useElementOnScreenOnce } from '../helpers/hooks';

import imgReg from './../../assets/img/about/about-myself.png';
import imgSmall from './../../assets/img/about/about-myself-small.png';

const Bio = props => {
  // Switcher
  const phoneMediaQuery = window.matchMedia('(max-width: 600px)');
  const img = phoneMediaQuery.matches ? imgSmall : imgReg;

  // observer
  const [elementRef, isElementAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 0.15,
  });

  const classHeadingRef = isElementAppeared ? 'moveInRight' : 'hidden';
  const classParagraphRef = isElementAppeared ? 'textClip' : 'hidden';

  return (
    <div className={`${classes.container} ${props.class}`}>
      <div className={`${classes.imgBox} moveInBottomIntro`}>
        <img
          src={img}
          alt="myself"
          className={`${classes.img} ${classes.myself} `}
        />
      </div>

      <div className={classes.contentBox}>
        <h2 className={`${classes.heading} ${classHeadingRef} heading-2`}>
          A journey of helping brands achieve greatness.
        </h2>
        <h3
          className={`${classes.paragraph} ${classes.paragraph1} ${classParagraphRef} heading-3`}
          ref={elementRef}
        >
          I'm an aspiring web developer residing in the Pearl of the Orient ---
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            Philippines
          </span>
          . I'm currently trying my luck to do a great headstart by getting my
          <span className={`${classes.highlightText} heading-2`}> first </span>
          job here in web development. I've just started learning programming
          back in
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            January 2022{' '}
          </span>
          without any computer education background.
        </h3>

        <h3
          className={`${classes.paragraph} ${classes.paragraph2} ${classParagraphRef} heading-3`}
        >
          Despite lacking of experience and background, what I could assert to
          you here is my
          <span className={`${classes.highlightText} heading-2`}> passion</span>
          . Yes, my passion! With this mindset, I've made websites that could
          <span className={`${classes.highlightText} heading-2`}> match </span>
          professionals in a short period of time. Thanks to the content
          creators online that turn web development into an interesting path.
          This is the only
          <span className={`${classes.highlightText} heading-2`}> virus </span>
          that I've gotten in this pandemic.
        </h3>

        <h3
          className={`${classes.paragraph} ${classes.paragraph3} ${classParagraphRef} heading-3`}
        >
          I know that the
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            orthodox{' '}
          </span>
          way of presenting yourself is hiding your flaws and showcasing your
          strengths, right? But I think I need to take the
          <span className={`${classes.highlightText} heading-2`}> other </span>
          way here at this time. Because my flaws are really the ones that
          defines my strength now --- a
          <span className={`${classes.highlightText} heading-2`}> fuel </span>
          of perseverance.
        </h3>
      </div>
    </div>
  );
};

export default Bio;
