import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import CarouselShowcase from '../carousel/CarouselShowcase';

import logo1 from './../../assets/img/tools/tools-sass.png';
import logo2 from './../../assets/img/tools/tools-react.png';
import logo3 from './../../assets/img/tools/tools-next.png';
import logo4 from './../../assets/img/tools/tools-nodejs.png';
import logo5 from './../../assets/img/tools/tools-redux.png';
import logo6 from './../../assets/img/tools/tools-mongoose.png';

const Tools = props => {
  // Navigation
  const [toWorksPage, setWorksPage] = useState(false);

  if (toWorksPage === true) return <Navigate to="/works" />;

  const clickCtaHandler = () => {
    setWorksPage(true);
  };

  const clickGhostHandler = () => {
    window.location.href = 'https://github.com/kershey28';
  };

  return (
    <CarouselShowcase
      text="Here are some of the tools that I've learned in my first 6 months..."
      logo1={logo1}
      logo2={logo2}
      logo3={logo3}
      logo4={logo4}
      logo5={logo5}
      logo6={logo6}
      alt1="SASS"
      alt2="React"
      alt3="Next JS"
      alt4="Node JS"
      alt5="Redux"
      alt6="Mongoose"
      cta="Discover Results"
      ghost="Check repositories"
      onClickCta={clickCtaHandler}
      onClickGhost={clickGhostHandler}
    />
  );
};

export default Tools;
