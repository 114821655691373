import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import classes from './WorksContent.module.css';
import { scrollIntoPosition } from '../helpers/helpers';

import Video from '../frames/Video';
import NumberedItem from '../layouts/NumberedItem';
import ButtonText from '../buttons/ButtonText';

import videoWebm from './../../assets/vid/works/works-paladise.webm';

const Paladise = props => {
  // Navigation
  const [toBrandPage, setBrandPage] = useState(false);

  if (toBrandPage === true) return <Navigate to="/works/paladise" />;

  const navigateHandler = () => {
    setBrandPage(true);
    scrollIntoPosition('top');
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <NumberedItem number="7" title="Paladise" class={classes.heading} />
      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          A tour website.
        </h2>

        <ul className={`${classes.contentBullet} heading-3`}>
          <li className={classes.bullet}>Hero animation</li>
          <li className={classes.bullet}>Best island</li>
          <li className={classes.bullet}>Gradient styling</li>
          <li className={classes.bullet}>1st website built</li>
        </ul>

        <ButtonText
          text="Discover Paladise"
          class={classes.button}
          onClick={navigateHandler}
        />
      </div>
    </div>
  );
};

export default Paladise;
