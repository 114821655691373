import svg from './../../assets/svg/sprite.svg';
import classes from './SVGIcons.module.css';

export const SVGFacebook = props => {
  return (
    <svg className={`${props.class} ${classes.icon}`}>
      <use href={`${svg}#icon-facebook`}></use>
    </svg>
  );
};

export const SVGEmail = props => {
  return (
    <svg className={`${props.class} ${classes.icon}`}>
      <use href={`${svg}#icon-mail`}></use>
    </svg>
  );
};

export const SVGPhone = props => {
  return (
    <svg className={`${props.class} ${classes.icon}`}>
      <use href={`${svg}#icon-phone`}></use>
    </svg>
  );
};

export const SVGGithub = props => {
  return (
    <svg className={`${props.class} ${classes.icon}`}>
      <use href={`${svg}#icon-github`}></use>
    </svg>
  );
};

export const SVGWeb = props => {
  return (
    <svg className={`${props.class} ${classes.icon}`}>
      <use href={`${svg}#icon-globe`}></use>
    </svg>
  );
};

export const SVGDownload = props => {
  return (
    <div>
      <svg className={`${props.class} ${classes.icon}`}>
        <use href={`${svg}#icon-download`}></use>
      </svg>{' '}
      Download CV
    </div>
  );
};
