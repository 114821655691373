import React, { useState, useEffect, useRef } from 'react';

import classes from './Video.module.css';

const Video = (props, ref) => {
  const videoRef = useRef(null);
  const [focus, setFocus] = useState(false);
  const [isVideoAppeared, setVideoAppeared] = useState(false);
  const cannotHover = window.matchMedia(`(hover:none)`).matches;
  const classRef = isVideoAppeared ? 'moveInBottom' : 'hidden';

  //playing video
  const loop = () => {
    videoRef.current.play();
  };

  const onEndedLoop = () => {
    //when ended, check if its focused, then loop
    if (focus) loop();
  };

  //handlers
  const MouseOverHandler = () => {
    setFocus(true);
  };

  const MouseOutHandler = () => {
    setFocus(false);
  };

  useEffect(() => {
    //when focused, then loop
    if (focus) loop();
  }, [focus]);

  //animation
  useEffect(() => {
    // functionality
    const playVideo = (entries, observer) => {
      const [entry] = entries;
      if (!entry.isIntersecting) {
        setFocus(false);
        return;
      }

      setTimeout(() => {
        setVideoAppeared(true);
      }, 1000);

      //play only for mobile
      if (cannotHover) {
        setTimeout(() => {
          setFocus(true);
        }, 3000);
      }

      //remove observer in desktop
      if (!cannotHover) {
        observer.unobserve(entry.target);
      }
    };

    //observer;
    const videoObserver = new IntersectionObserver(playVideo, {
      root: null,
      threshold: 1,
    });

    videoObserver.observe(videoRef.current);
  }, [cannotHover]);

  return (
    <div className={`${classes.container} ${props.class} ${classRef}`}>
      <video
        className={classes.video}
        ref={videoRef}
        onMouseOver={MouseOverHandler}
        onMouseOut={MouseOutHandler}
        muted={true}
        onEnded={onEndedLoop}
      >
        <source src={props.webm} type="video/webm" />
        Your browser is not supported!
      </video>
    </div>
  );
};

export default Video;
