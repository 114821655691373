import Header from '../components/layouts/Header';
import Bio from '../components/about/Bio';
import History from '../components/about/History';
import Approach from '../components/about/Approach';
import Timeline from '../components/about/Timeline';
import Leisure from '../components/about/Leisure';
import Inviter from '../components/layouts/Inviter';

import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import React from 'react';
import classes from './About.module.css';

const About = (props, ref) => {
  // Navigation
  const [toPracticesPage, setPracticesPage] = useState(false);

  if (toPracticesPage === true) return <Navigate to="/practices" />;

  const inviterText = 'Want to see how I do projects?';
  const inviterCta = 'Yes, tell me about it!';

  const inviterClickHandler = () => {
    setPracticesPage(true);
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <Header
        text1="About me"
        text2="Minimalist"
        textMini="yet"
        textLast="Creative"
        class={classes.introBox}
        classTextLast={classes.introText}
        ref={ref}
      />

      <Bio />
      <History />
      <Approach />
      <Timeline />
      <Leisure />

      <Inviter
        text={inviterText}
        cta={inviterCta}
        onClick={inviterClickHandler}
      />
    </div>
  );
};

export default React.forwardRef(About);
