import classes from './PracticesContent.module.css';

import Video from '../frames/Video';
import NumberedItem from '../layouts/NumberedItem';
import ButtonText from '../buttons/ButtonText';

import videoWebm from './../../assets/vid/practices/practices-mvc.webm';

const Mvc = props => {
  const navigateHandler = () => {
    window.location.href = 'https://github.com/kershey28';
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <NumberedItem
        number="5"
        title="MVC Architecture"
        class={classes.heading}
      />
      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Easily
          <span className={classes.highlightTextHeading}> modifiable </span>
          for easy maintenance.
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          Websites are never really
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            complete
          </span>
          , it must be constantly updated with fresh content in order to engage
          and grow your customers --- to keep up with these fast-paced,
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            ever-changing{' '}
          </span>
          demands.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          A website that has a good code
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            structure{' '}
          </span>
          like MVC means avoiding costly disasters. My service is not only about
          'til the deployment, but the
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            quality{' '}
          </span>
          that will last.
        </h3>

        <ButtonText
          text="Go to Repositories"
          class={classes.button}
          onClick={navigateHandler}
        />
      </div>
    </div>
  );
};

export default Mvc;
