import classes from './Footer.module.css';

import { SVGFacebook, SVGEmail, SVGGithub } from './../icons/SVGIcons';

const Footer = props => {
  return (
    <footer className={`${classes.container} ${props.class}`}>
      <p className={classes.copyright}>Philippines &copy; 2022</p>

      <ul className={classes.list}>
        <li className={classes.item}>
          <a href="https://carinokershey@gmail.com/" className={classes.link}>
            <SVGEmail />
          </a>
        </li>
        <li className={classes.item}>
          <a
            href="https://www.facebook.com/kersheycarino/"
            className={classes.link}
          >
            <SVGFacebook />
          </a>
        </li>
        <li className={classes.item}>
          <a href="https://github.com/kershey28" className={classes.link}>
            <SVGGithub />
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
