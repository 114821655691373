import Header from '../components/layouts/Header';
import ContactCard from '../components/cards/ContactCard';
import ButtonBox from '../components/buttons/ButtonBox';
import { SVGDownload } from '../components/icons/SVGIcons';

import { saveAs } from 'file-saver';
import classes from './Contact.module.css';
import React from 'react';

const Contact = (props, ref) => {
  const clickCtaHandler = () => {
    saveAs(
      'https://drive.google.com/file/d/1Cg6JUWinc0xJTdhdx-SbymDdURRbX22o/view?usp=sharing',
      'carino_kershey_CV.pdf'
    );
  };

  const clickGhostHandler = () => {
    window.location.href = 'https://github.com/kershey28';
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <Header
        text1="Contact"
        text2="Let's talk about"
        textMini="your"
        textLast="Product"
        class={classes.introBox}
        classTextLast={classes.introText}
        ref={ref}
      />
      <ContactCard class={classes.card} />
      <ButtonBox
        cta={<SVGDownload />}
        ghost="Go to Repositories"
        class={classes.btn}
        onClickCta={clickCtaHandler}
        onClickGhost={clickGhostHandler}
      />
    </div>
  );
};

export default React.forwardRef(Contact);
