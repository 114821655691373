import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import classes from './WorksContent.module.css';
import { scrollIntoPosition } from '../helpers/helpers';

import Video from '../frames/Video';
import NumberedItem from '../layouts/NumberedItem';
import ButtonText from '../buttons/ButtonText';

import videoWebm from './../../assets/vid/works/works-cashy.webm';

const Cashy = props => {
  // Navigation
  const [toBrandPage, setBrandPage] = useState(false);

  if (toBrandPage === true) return <Navigate to="/works/cashy" />;

  const navigateHandler = () => {
    setBrandPage(true);
    scrollIntoPosition('top');
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <NumberedItem number="5" title="Cashy" class={classes.heading} />
      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          A banking application.
        </h2>

        <ul className={`${classes.contentBullet} heading-3`}>
          <li className={classes.bullet}>
            Log in with a username and password
          </li>
          <li className={classes.bullet}>View and sort monetary movements</li>
          <li className={classes.bullet}>Transfer money to another account</li>
          <li className={classes.bullet}>Request a loan from the bank</li>
          <li className={classes.bullet}>Delete account</li>
          <li className={classes.bullet}>Log-out timer</li>
        </ul>

        <ButtonText
          text="Discover Cashy"
          class={classes.button}
          onClick={navigateHandler}
        />
      </div>
    </div>
  );
};

export default Cashy;
