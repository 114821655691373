import { ButtonCTA, ButtonGhost } from './ButtonReg';

import classes from './ButtonBox.module.css';

const ButtonBox = props => {
  return (
    <div className={`${classes.container} ${props.class}`}>
      <ButtonCTA text={props.cta} onClick={props.onClickCta} />
      <ButtonGhost text={props.ghost} onClick={props.onClickGhost} />
    </div>
  );
};

export default ButtonBox;
