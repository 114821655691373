import classes from './Inviter.module.css';

import { ButtonCTA } from './../../components/buttons/ButtonReg';

const Inviter = props => {
  return (
    <div className={`${classes.container} ${props.class}`}>
      <h1 className={`${classes.text} heading-1`}>{props.text}</h1>
      <ButtonCTA
        text={props.cta}
        onClick={props.onClick}
        className={classes.button}
      />
    </div>
  );
};

export default Inviter;
