import classes from './Practices.module.css';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import Header from '../components/layouts/Header';
import Tools from '../components/practices/Tools';
import Responsive from '../components/practices/Responsive';
import Optimization from '../components/practices/Optimization';
import UserInterface from '../components/practices/UserInterface';
import Compatibility from '../components/practices/Compatibility';
import Mvc from '../components/practices/Mvc';
import Inviter from '../components/layouts/Inviter';

const Practices = (props, ref) => {
  // Navigation
  const [toWorksPage, setWorksPage] = useState(false);

  if (toWorksPage === true) return <Navigate to="/works" />;

  const inviterText = 'Want to see some results from these practices?';
  const inviterCta = 'Yes, discover results';

  const inviterClickHandler = () => {
    setWorksPage(true);
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <Header
        text1="Practices"
        text2="Efficient"
        textMini="yet"
        textLast="Extra-mile"
        class={classes.introBox}
        classTextLast={classes.introText}
        ref={ref}
      />
      <Tools />

      <Responsive />
      <Optimization />
      <UserInterface />
      <Compatibility />
      <Mvc />

      <Inviter
        text={inviterText}
        cta={inviterCta}
        onClick={inviterClickHandler}
      />
    </div>
  );
};

export default React.forwardRef(Practices);
