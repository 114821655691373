import { Navigate } from 'react-router-dom';
import React, { useState } from 'react';

import NumberedItem from '../../components/layouts/NumberedItem';
import Image from '../../components/frames/Image';
import Video from '../../components/frames/Video';
import Ripple from '../../components/floaters/Ripple';
import ButtonBox from './../../components/buttons/ButtonBox';
import ButtonText from '../../components/buttons/ButtonText';

import classes from './IndividualWorks.module.css';
import { scrollIntoPosition } from '../../components/helpers/helpers';

import brandImg from './../../assets/img/logos/logo-cashy.png';
import videoWebm from './../../assets/vid/responsive/responsive-cashy.webm';

const CashyPage = (props, ref) => {
  // Navigation
  const [toGoBackPage, setGoBackPage] = useState(false);

  if (toGoBackPage === true) return <Navigate to="/works" />;

  const navigateHandler = () => {
    setGoBackPage(true);
    scrollIntoPosition('top');
  };

  const clickCtaHandler = () => {
    window.location.href = 'https://cashybank.netlify.app';
  };

  const clickGhostHandler = () => {
    window.location.href = 'https://github.com/kershey28';
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <Ripple />
      <NumberedItem
        number="5"
        title="Your Better Banking Connection"
        class={classes.heading}
        ref={ref}
      />

      <Image img={brandImg} class={classes.brandImg} />

      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Making bank transactions conveniently.
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          A
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            vanilla{' '}
          </span>
          JavaScript application with the functionality to sign into account for
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            monetary{' '}
          </span>
          activities. This is built with HTML and
          <span className={`${classes.highlightText} heading-2`}> SCSS</span>.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          This is the
          <span className={`${classes.highlightText} heading-2`}> first </span>
          application that I built with
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            functionality
          </span>
          . At first, I was doubting myself if I could already handle
          <span className={`${classes.highlightText} heading-2`}> </span>
          higher
          <span className={`${classes.highlightText} heading-2`}> level </span>
          JS from the previous ones I had built. The real struggle that I
          encountered here was managing page
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            transitions
          </span>
          .
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph3} heading-3`}
        >
          Given that, this is only a
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            single-page{' '}
          </span>
          application and without using any
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            libraries
          </span>
          . I really came up into a solution that do the job using only vanilla
          JS. I also implemented pop-ups in every
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            interaction{' '}
          </span>
          of the user for better UX.
        </h3>

        <ButtonText
          text="Check other brands"
          onClick={navigateHandler}
          class={classes.buttonBack}
        />
        <ButtonBox
          cta="Discover live"
          ghost="Go to repository"
          class={classes.button}
          onClickCta={clickCtaHandler}
          onClickGhost={clickGhostHandler}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(CashyPage);
