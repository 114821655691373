import React from 'react';

import classes from './NumberedItem.module.css';
import { useElementOnScreenOnce } from '../helpers/hooks';

const NumberedItem = (props, ref) => {
  // observer
  const [textRef, isTextAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 0.15,
  });

  const classTextRef = isTextAppeared ? 'moveInRight' : 'hidden';

  return (
    <div
      className={`${classes.container} ${props.class} ${classTextRef}`}
      ref={textRef}
    >
      <h1 className={`${classes.number} heading-1`}>0{props.number}/</h1>
      <h1 className={`${classes.title} heading-display`} ref={ref}>
        {props.title}
      </h1>
    </div>
  );
};

export default React.forwardRef(NumberedItem);
