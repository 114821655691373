import { Navigate } from 'react-router-dom';
import React, { useState } from 'react';

import NumberedItem from '../../components/layouts/NumberedItem';
import Image from '../../components/frames/Image';
import Video from '../../components/frames/Video';
import Ripple from '../../components/floaters/Ripple';
import ButtonBox from './../../components/buttons/ButtonBox';
import ButtonText from '../../components/buttons/ButtonText';

import classes from './IndividualWorks.module.css';
import { scrollIntoPosition } from '../../components/helpers/helpers';

import brandImg from './../../assets/img/logos/logo-gorecipe.png';
import videoWebm from './../../assets/vid/responsive/responsive-gorecipe.webm';

const GorecipePage = (props, ref) => {
  // Navigation
  const [toGoBackPage, setGoBackPage] = useState(false);

  if (toGoBackPage === true) return <Navigate to="/works" />;

  const navigateHandler = () => {
    setGoBackPage(true);
    scrollIntoPosition('top');
  };

  const clickCtaHandler = () => {
    window.location.href = 'https://gorecipe.netlify.app';
  };

  const clickGhostHandler = () => {
    window.location.href = 'https://github.com/kershey28';
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <Ripple />
      <NumberedItem
        number="3"
        title="Your Handy To-cook List"
        class={classes.heading}
        ref={ref}
      />

      <Image img={brandImg} class={classes.brandImg} />

      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Searching recipes and uploading your own.
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          A
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            vanilla{' '}
          </span>
          JavaScript application that interacts with the
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            Forkify{' '}
          </span>
          API to fetch and display recipe food data. This is built with HTML and
          <span className={`${classes.highlightText} heading-2`}> SCSS</span>.
          It uses
          <span className={`${classes.highlightText} heading-2`}> Parcel </span>
          as module bundler and NPM as package manager. Its structure is built
          with
          <span className={`${classes.highlightText} heading-2`}> MVC </span>
          architecture.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          The user can
          <span className={`${classes.highlightText} heading-2`}> search </span>
          for recipes, and
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            bookmark{' '}
          </span>
          it via local storage. The user can easily increase or decrease
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            servings{' '}
          </span>
          as per need and can view detailed directions.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph3} heading-3`}
        >
          Managing page
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            transitions{' '}
          </span>{' '}
          are more complex in this one because this is a
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            single-page{' '}
          </span>
          application without using any
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            libraries
          </span>
          . Nevertheless, I really came up into a solution that do the job using
          only vanilla JS. I also implemented
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            limitation{' '}
          </span>
          on uploading a recipe into twice a day only to avoid flooding the
          <span className={`${classes.highlightText} heading-2`}> server</span>.
        </h3>

        <ButtonText
          text="Check other brands"
          onClick={navigateHandler}
          class={classes.buttonBack}
        />

        <ButtonBox
          cta="Discover live"
          ghost="Go to repository"
          class={classes.button}
          onClickCta={clickCtaHandler}
          onClickGhost={clickGhostHandler}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(GorecipePage);
