import classes from './ButtonText.module.css';

const ButtonText = props => {
  return (
    <button
      className={`${classes.btnText} ${props.class}`}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export default ButtonText;
