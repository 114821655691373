import classes from './AboutContent.module.css';

import { useElementOnScreenOnce } from '../helpers/hooks';

import img from './../../assets/img/about/about-call.png';

const History = props => {
  // observer
  const [imageRef, isImageAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 0.15,
  });

  const [elementRef, isElementAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 0.15,
  });

  const classImageRef = isImageAppeared ? 'moveInBottom' : 'hidden';
  const classHeadingRef = isElementAppeared ? 'moveInRight' : 'hidden';
  const classParagraphRef = isElementAppeared ? 'textClip' : 'hidden';

  return (
    <div className={`${classes.container} ${props.class}`}>
      <div className={`${classes.imgBox} ${classImageRef}`}>
        <img
          src={img}
          alt="call center agent"
          className={classes.img}
          ref={imageRef}
        />
      </div>

      <div className={classes.contentBox}>
        <h2 className={`${classes.heading} ${classHeadingRef} heading-2`}>
          How it started?
        </h2>

        <h3
          className={`${classes.paragraph} ${classParagraphRef} ${classes.paragraph1} heading-3`}
          ref={elementRef}
        >
          Back in
          <span className={`${classes.highlightText} heading-2`}> 2021</span>,
          web development already got my interest but due to not being well-off,
          I didn't have a
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            computer
          </span>
          . But that didn't stop me, I worked as a call center agent at
          <span className={`${classes.highlightText} heading-2`}> Telus</span>;
          then, bought one to pursue this path.
        </h3>

        <h3
          className={`${classes.paragraph} ${classParagraphRef} ${classes.paragraph2} heading-3`}
        >
          In May
          <span className={`${classes.highlightText} heading-2`}> 2022</span>, I
          decided to take my resignation to expedite building websites. Without
          a
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            guarantee{' '}
          </span>
          that I will secure a career on a field that is really out of my
          environment. I've made this
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            decision{' '}
          </span>
          even my progress in the BPO industry was accelerating.
        </h3>

        <h3
          className={`${classes.paragraph} ${classParagraphRef} ${classes.paragraph3} heading-3`}
        >
          I know that it's not a good move to leave your job without guarantee
          on securing the job that you
          <span className={`${classes.highlightText} heading-2`}> aim </span>
          for. But sometimes, that risk, that
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            pressure
          </span>
          , really forces ourselves to exceed our
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            limitations
          </span>
          .
        </h3>
      </div>
    </div>
  );
};

export default History;
