import classes from './Logo.module.css';

const Logo = props => {
  return (
    <div className={`${classes.container} ${props.class}`}>
      <span className={`${classes.text} ${classes.text1}`}>Kershey</span>
      <span className={`${classes.text} ${classes.text2}`}>Cariño.</span>
    </div>
  );
};

export default Logo;
