import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import CarouselShowcase from '../carousel/CarouselShowcase';

import logo1 from './../../assets/img/logoTrans/carousel-paladise.png';
import logo2 from './../../assets/img/logoTrans/carousel-newtral.png';
import logo3 from './../../assets/img/logoTrans/carousel-gorecipe.png';
import logo4 from './../../assets/img/logoTrans/carousel-rentz.png';
import logo5 from './../../assets/img/logoTrans/carousel-yourtrips.png';
import logo6 from './../../assets/img/logoTrans/carousel-saul.png';

const Brands = props => {
  // Navigation
  const [toContactPage, setContactPage] = useState(false);

  if (toContactPage === true) return <Navigate to="/contact" />;

  const clickCtaHandler = () => {
    setContactPage(true);
  };

  const clickGhostHandler = () => {
    window.location.href = 'https://github.com/kershey28';
  };

  return (
    <CarouselShowcase
      text="Here are some of the websites that I've built from scratch for the past 2 months..."
      logo1={logo1}
      logo2={logo2}
      logo3={logo3}
      logo4={logo4}
      logo5={logo5}
      logo6={logo6}
      alt1="Paladise"
      alt2="Newtral"
      alt3="Gorecipe"
      alt4="Rentz"
      alt5="Yourtrips"
      alt6="Saul"
      cta="Discuss your product"
      ghost="Check repositories"
      onClickCta={clickCtaHandler}
      onClickGhost={clickGhostHandler}
    />
  );
};

export default Brands;
