import classes from './CarouselLogo.module.css';

const CarouselLogo = props => {
  return (
    <div className={`${classes.container} ${props.class}`}>
      <figure className={classes.carousel}>
        <div className={classes.content}>
          <div className={classes.item}>
            <img src={props.logo1} alt={props.alt1} className={classes.img} />
          </div>
          <div className={classes.item}>
            <img src={props.logo2} alt={props.alt2} className={classes.img} />
          </div>
          <div className={classes.item}>
            <img src={props.logo3} alt={props.alt3} className={classes.img} />
          </div>
        </div>
      </figure>
    </div>
  );
};

export default CarouselLogo;
