import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import classes from './PracticesContent.module.css';
import { scrollIntoPosition } from '../helpers/helpers';

import Video from '../frames/Video';
import NumberedItem from '../layouts/NumberedItem';
import ButtonText from '../buttons/ButtonText';

import videoWebm from './../../assets/vid/practices/practices-compatibility.webm';

const Compatibility = props => {
  // Navigation
  const [toBrandPage, setBrandPage] = useState(false);

  if (toBrandPage === true) return <Navigate to="/works/yourtrips" />;

  const navigateHandler = () => {
    setBrandPage(true);
    scrollIntoPosition('top');
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <NumberedItem
        number="4"
        title="Cross - Browser Compatibility"
        class={classes.heading}
      />
      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Making look and work the same in
          <span className={classes.highlightTextHeading}> all </span>
          browsers.
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          Your visitors will use a
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            variety{' '}
          </span>
          of browsers depending on their
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            preference
          </span>
          . Mostly, Mac users may be sworn to Safari while Google lovers might
          opt for Chrome.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          But
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            no matter{' '}
          </span>
          what browser they are using, it's my job to deliver the
          <span className={`${classes.highlightText} heading-2`}> same </span>
          user experience we aim for to get the most out of your product.
        </h3>

        <ButtonText
          text="Discover Yourtrips"
          class={classes.button}
          onClick={navigateHandler}
        />
      </div>
    </div>
  );
};

export default Compatibility;
