import classes from './AboutContent.module.css';

import { useElementOnScreenOnce } from '../helpers/hooks';

import img from './../../assets/img/about/about-sales.png';

const Approach = props => {
  // observer
  const [imageRef, isImageAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 0.15,
  });

  const [elementRef, isElementAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 0.15,
  });

  const classImageRef = isImageAppeared ? 'moveInBottom' : 'hidden';
  const classHeadingRef = isElementAppeared ? 'moveInRight' : 'hidden';
  const classParagraphRef = isElementAppeared ? 'textClip' : 'hidden';

  return (
    <div className={`${classes.container} ${props.class}`}>
      <div className={`${classes.imgBox} ${classImageRef}`}>
        <img
          src={img}
          alt="sales consultant"
          className={classes.img}
          ref={imageRef}
        />
      </div>

      <div className={classes.contentBox}>
        <h2 className={`${classes.heading} ${classHeadingRef} heading-2`}>
          How I've done it?
        </h2>
        <h3
          className={`${classes.paragraph} ${classes.paragraph1} ${classParagraphRef} heading-3`}
          ref={elementRef}
        >
          Working as a customer
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            representative{' '}
          </span>
          from midnight 'til before noon. Then, studying
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            documentations{' '}
          </span>
          and tutorials in programming after that.
        </h3>

        <h3
          className={`${classes.paragraph} ${classes.paragraph2} ${classParagraphRef} heading-3`}
        >
          Thanks on the benefits of working from
          <span className={`${classes.highlightText} heading-2`}> home</span>,
          this was made easier through this set-up. I used to be a working
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            student{' '}
          </span>
          so it's really not that hard for me.
        </h3>

        <h3
          className={`${classes.paragraph} ${classes.paragraph3} ${classParagraphRef} heading-3`}
        >
          Also, thanks to my previous job as Sales Consultant in
          <span className={`${classes.highlightText} heading-2`}> M&S</span>. I
          got fond of manipulating visual designs to make customers buy my
          <span className={`${classes.highlightText} heading-2`}> target </span>
          products. This really helps me to be flexible on my
          <span className={`${classes.highlightText} heading-2`}> sale </span>
          approaches depending on our target market.
        </h3>
      </div>
    </div>
  );
};

export default Approach;
