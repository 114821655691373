import classes from './ContactCard.module.css';

import {
  SVGFacebook,
  SVGEmail,
  SVGPhone,
  SVGGithub,
  SVGWeb,
} from '../icons/SVGIcons';

const ContactCard = props => {
  return (
    <div className={`${classes.container} ${props.class}`}>
      <div className={classes.cardBox}>
        <div className={classes.card}>
          <div className={`${classes.front} ${classes.side}`}>
            <h1 className={classes.logo}>Kershey Cariño</h1>
          </div>

          <div className={`${classes.back} ${classes.side}`}>
            <h3 className={classes.name}>Kershey Cariño</h3>
            <p className={classes.job}>Front-end developer</p>
            <div className={classes.info}>
              <p className={classes.paragraph}>
                <span className={classes.property}>
                  <SVGEmail />
                </span>
                carinokershey@gmail.com
              </p>
              <p className={classes.paragraph}>
                <span className={classes.property}>
                  <SVGPhone />
                </span>
                0995 - 857 - 3694
              </p>
              <p className={classes.paragraph}>
                <span className={classes.property}>
                  <SVGFacebook />
                </span>
                facebook.com/kersheycarino
              </p>
              <p className={classes.paragraph}>
                <span className={classes.property}>
                  <SVGGithub />
                </span>
                github.com/kershey28
              </p>
              <p className={classes.paragraph}>
                <span className={classes.property}>
                  <SVGWeb />
                </span>
                kershey.netlify.app
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
