import { Navigate } from 'react-router-dom';
import React, { useState } from 'react';

import NumberedItem from '../../components/layouts/NumberedItem';
import Image from '../../components/frames/Image';
import Video from '../../components/frames/Video';
import Ripple from '../../components/floaters/Ripple';
import ButtonBox from './../../components/buttons/ButtonBox';
import ButtonText from '../../components/buttons/ButtonText';

import classes from './IndividualWorks.module.css';
import { scrollIntoPosition } from '../../components/helpers/helpers';

import brandImg from './../../assets/img/logos/logo-newtral.png';
import videoWebm from './../../assets/vid/responsive/responsive-newtral.webm';

const NewtralPage = (props, ref) => {
  // Navigation
  const [toGoBackPage, setGoBackPage] = useState(false);

  if (toGoBackPage === true) return <Navigate to="/works" />;

  const navigateHandler = () => {
    setGoBackPage(true);
    scrollIntoPosition('top');
  };

  const clickCtaHandler = () => {
    window.location.href = 'https://newtral.netlify.app';
  };

  const clickGhostHandler = () => {
    window.location.href = 'https://github.com/kershey28';
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <Ripple />
      <NumberedItem
        number="1"
        title="The Men's Neutral Apparel"
        class={classes.heading}
        ref={ref}
      />

      <Image img={brandImg} class={classes.brandImg} />

      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Keeping men's fashion simple yet in style.
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          A<span className={`${classes.highlightText} heading-2`}> React </span>
          application with the functionality to add and remove items from the
          <span className={`${classes.highlightText} heading-2`}> cart</span>.
          This is built with SCSS and React
          <span className={`${classes.highlightText} heading-2`}> Router</span>.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          This is the
          <span className={`${classes.highlightText} heading-2`}> first </span>
          application that I built with React and using a library. At first, I
          really had a hard time
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            adapting{' '}
          </span>
          into the new system. It took me one week to make myself at
          <span className={`${classes.highlightText} heading-2`}> ease </span>
          using my coding style with this tool.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph3} heading-3`}
        >
          Managing page
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            transitions{' '}
          </span>
          using this library is easy-peasy compare from the previous ones that I
          made; also, it's more
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            modular{' '}
          </span>
          that really saves time. Specifically, I built this shopping site based
          from my fashion
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            preference{' '}
          </span>
          in being classic and neutral.
        </h3>

        <ButtonText
          text="Check other brands"
          onClick={navigateHandler}
          class={classes.buttonBack}
        />

        <ButtonBox
          cta="Discover live"
          ghost="Go to repository"
          class={classes.button}
          onClickCta={clickCtaHandler}
          onClickGhost={clickGhostHandler}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(NewtralPage);
