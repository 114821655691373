import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import classes from './WorksContent.module.css';
import { scrollIntoPosition } from '../helpers/helpers';

import Video from '../frames/Video';
import NumberedItem from '../layouts/NumberedItem';
import ButtonText from '../buttons/ButtonText';

import videoWebm from './../../assets/vid/works/works-yourtrips.webm';

const Yourtrips = props => {
  // Navigation
  const [toBrandPage, setBrandPage] = useState(false);

  if (toBrandPage === true) return <Navigate to="/works/yourtrips" />;

  const navigateHandler = () => {
    setBrandPage(true);
    scrollIntoPosition('top');
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <NumberedItem number="4" title="Yourtrips" class={classes.heading} />
      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          A to-go list application.
        </h2>
        <ul className={`${classes.contentBullet} heading-3`}>
          <li className={classes.bullet}>Display map using Leaflet library</li>
          <li className={classes.bullet}>
            Position automatically obtained using Geolocation API
          </li>
          <li className={classes.bullet}>Create to-go reminders</li>
          <li className={classes.bullet}>Show target destinations on map</li>
          <li className={classes.bullet}>
            Save reminders and username to local storage
          </li>
          <li className={classes.bullet}>Complementary styling</li>
        </ul>

        <ButtonText
          text="Discover Yourtrips"
          class={classes.button}
          onClick={navigateHandler}
        />
      </div>
    </div>
  );
};

export default Yourtrips;
