import { Navigate } from 'react-router-dom';
import React, { useState } from 'react';

import NumberedItem from '../../components/layouts/NumberedItem';
import Image from '../../components/frames/Image';
import Video from '../../components/frames/Video';
import Ripple from '../../components/floaters/Ripple';
import ButtonBox from './../../components/buttons/ButtonBox';
import ButtonText from '../../components/buttons/ButtonText';

import classes from './IndividualWorks.module.css';
import { scrollIntoPosition } from '../../components/helpers/helpers';

import brandImg from './../../assets/img/logos/logo-rentz.png';
import videoWebm from './../../assets/vid/responsive/responsive-rentz.webm';

const RentzPage = (props, ref) => {
  // Navigation
  const [toGoBackPage, setGoBackPage] = useState(false);

  if (toGoBackPage === true) return <Navigate to="/works" />;

  const navigateHandler = () => {
    setGoBackPage(true);
    scrollIntoPosition('top');
  };

  const clickCtaHandler = () => {
    window.location.href = 'https://rentz.netlify.app';
  };

  const clickGhostHandler = () => {
    window.location.href = 'https://github.com/kershey28';
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <Ripple />
      <NumberedItem
        number="2"
        title="Your Dream Motorcycle Rental"
        class={classes.heading}
        ref={ref}
      />

      <Image img={brandImg} class={classes.brandImg} />

      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Granting dream bikes in a limited time.
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          A<span className={`${classes.highlightText} heading-2`}> React </span>
          application with the functionality to
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            validate{' '}
          </span>
          details in the form before letting the user to submit it. This is
          built with SCSS and React
          <span className={`${classes.highlightText} heading-2`}> Router</span>.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          This is the
          <span className={`${classes.highlightText} heading-2`}> dream </span>
          motorcycle rental that every rider is wishing for. I built this from
          the idea of dreaming a sport
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            motorcycle{' '}
          </span>
          . Yes, I'm still dreaming :) I've never driven a motorcycle in my life
          but hoping I will own
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            someday{' '}
          </span>
          through this new career.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph3} heading-3`}
        >
          Actually, I was trying to buy one back then --- not a sport motorcycle
          though --- but I'd decided to
          <span className={`${classes.highlightText} heading-2`}> invest </span>
          it by buying a computer to
          <span className={`${classes.highlightText} heading-2`}> pursue </span>
          and start gambling here in web development{' '}
          <span className={`${classes.highlightText} heading-2`}> path</span>.
        </h3>

        <ButtonText
          text="Check other brands"
          onClick={navigateHandler}
          class={classes.buttonBack}
        />

        <ButtonBox
          cta="Discover live"
          ghost="Go to repository"
          class={classes.button}
          onClickCta={clickCtaHandler}
          onClickGhost={clickGhostHandler}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(RentzPage);
