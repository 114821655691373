import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import ButtonBox from '../components/buttons/ButtonBox';
import VerticalAdjaText from '../components/typography/VerticalAdjaText';
import Ball from '../components/floaters/Ball';

import React from 'react';
import classes from './Home.module.css';

const Home = (props, ref) => {
  // Navigation
  const [toContactPage, setContactPage] = useState(false);
  const [toWorksPage, setWorksPage] = useState(false);

  if (toContactPage === true) return <Navigate to="/contact" />;
  if (toWorksPage === true) return <Navigate to="/works" />;

  const clickCtaHandler = () => {
    setWorksPage(true);
  };

  const clickGhostHandler = () => {
    setContactPage(true);
  };

  return (
    <div className={classes.container}>
      <div className={classes.contentBox}>
        <h1 className={classes.text} ref={ref}>
          <span className={classes.text1}>Making </span>
          <VerticalAdjaText
            small="your"
            large="brand"
            animation="false"
            classText={classes.text3}
            classTextSmall={classes.text2}
          />
          <span className={`${classes.text4} heading-display`}> special</span>
        </h1>

        <ButtonBox
          cta="Discover"
          ghost="Contact"
          class={classes.button}
          onClickCta={clickCtaHandler}
          onClickGhost={clickGhostHandler}
        />
      </div>

      <div className={classes.floaterBox}>
        <Ball />
      </div>
    </div>
  );
};
export default React.forwardRef(Home);
