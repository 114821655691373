import classes from './CarouselShowcase.module.css';

import CarouselLogo from '../carousel/CarouselLogo';
import ButtonBox from '../../components/buttons/ButtonBox';

const CarouselShowcase = props => {
  return (
    <div className={`${classes.container} ${props.class} reveal`}>
      <h2 className={`${classes.heading} heading-2`}>{props.text}</h2>

      <CarouselLogo
        logo1={props.logo1}
        logo2={props.logo2}
        logo3={props.logo3}
        alt1={props.alt1}
        alt2={props.alt2}
        alt3={props.alt3}
        class={classes.carousel1}
      />
      <CarouselLogo
        logo1={props.logo4}
        logo2={props.logo5}
        logo3={props.logo6}
        alt1={props.alt4}
        alt2={props.alt5}
        alt3={props.alt6}
        class={classes.carousel2}
      />

      <ButtonBox
        cta={props.cta}
        ghost={props.ghost}
        class={classes.btn}
        onClickCta={props.onClickCta}
        onClickGhost={props.onClickGhost}
      />
    </div>
  );
};

export default CarouselShowcase;
