import classes from './Works.module.css';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import Header from '../components/layouts/Header';
import Brands from '../components/works/Brands';
import Newtral from '../components/works/Newtral';
import Rentz from '../components/works/Rentz';
import Gorecipe from '../components/works/Gorecipe';
import Yourtrips from '../components/works/Yourtrips';
import Cashy from '../components/works/Cashy';
import Saul from '../components/works/Saul';
import Paladise from '../components/works/Paladise';
import Inviter from '../components/layouts/Inviter';

const Works = (props, ref) => {
  // Navigation
  const [toContactPage, setContactPage] = useState(false);

  if (toContactPage === true) return <Navigate to="/contact" />;

  const inviterText = 'Want to discuss your product?';
  const inviterCta = "Yes, let's do it!";

  const inviterClickHandler = () => {
    setContactPage(true);
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <Header
        text1="Works"
        text2="Meticulous"
        textMini="coz it's"
        textLast="Special"
        class={classes.introBox}
        classTextLast={classes.introText}
        ref={ref}
      />

      <Brands />
      <Newtral />
      <Rentz />
      <Gorecipe />
      <Yourtrips />
      <Cashy />
      <Saul />
      <Paladise />

      <Inviter
        text={inviterText}
        cta={inviterCta}
        onClick={inviterClickHandler}
      />
    </div>
  );
};

export default React.forwardRef(Works);
