import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import classes from './PracticesContent.module.css';
import { scrollIntoPosition } from '../helpers/helpers';

import Video from '../frames/Video';
import NumberedItem from '../layouts/NumberedItem';
import ButtonText from '../buttons/ButtonText';

import videoWebm from './../../assets/vid/practices/practices-responsive.webm';

const Responsive = props => {
  // Navigation
  const [toBrandPage, setBrandPage] = useState(false);

  if (toBrandPage === true) return <Navigate to="/works/cashy" />;

  const navigateHandler = () => {
    setBrandPage(true);
    scrollIntoPosition('top');
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <NumberedItem
        number="1"
        title="Perfect responsive design"
        class={classes.heading}
      />
      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Making it responsive for
          <span className={classes.highlightTextHeading}> all </span>
          screen sizes.
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          The
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            essence{' '}
          </span>
          of a website cannot be really
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            defined{' '}
          </span>
          if some users weren't able to
          <span className={`${classes.highlightText} heading-2`}> access </span>
          it. Consequently, you will lose a lot of potential customers if your
          website is not{' '}
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            mobile-friendly{' '}
          </span>
          . Due to the fact that the majority of online traffic is coming from
          mobile, but desktop is still a close second.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          But worry
          <span className={`${classes.highlightText} heading-2`}> no more</span>
          , you don't need to choose what screen sizes you want to
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            prioritize{' '}
          </span>
          , I will absolutely give you the
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            best{' '}
          </span>{' '}
          of both worlds.
        </h3>

        <ButtonText
          text="Discover Cashy"
          class={classes.button}
          onClick={navigateHandler}
        />
      </div>
    </div>
  );
};

export default Responsive;
