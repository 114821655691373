import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import classes from './PracticesContent.module.css';
import { scrollIntoPosition } from '../helpers/helpers';

import Video from '../frames/Video';
import NumberedItem from '../layouts/NumberedItem';
import ButtonText from '../buttons/ButtonText';

import videoWebm from './../../assets/vid/practices/practices-optimization.webm';

const Optimization = props => {
  // Navigation
  const [toBrandPage, setBrandPage] = useState(false);

  if (toBrandPage === true) return <Navigate to="/works/saul" />;

  const navigateHandler = () => {
    setBrandPage(true);
    scrollIntoPosition('top');
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <NumberedItem
        number="2"
        title="Web Performance Optimization"
        class={classes.heading}
      />
      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Not letting visitors to wait is one of the
          <span className={classes.highlightTextHeading}> keys </span>
          for great user experience.
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          Optimizing your website to load
          <span className={`${classes.highlightText} heading-2`}> faster </span>
          will make it more welcoming to visitors and provide the best
          experience. Therefore, when people have a
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            positive{' '}
          </span>
          feeling about your site, this makes them more likely to
          <span className={`${classes.highlightText} heading-2`}> return </span>
          .
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          I make this happen by ensuring all the file size that I attached and
          codes that I input are
          <span className={`${classes.highlightText} heading-2`}> minimal</span>
          . One of the ways is images must be
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            resized{' '}
          </span>{' '}
          enough to the space it need and
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            converted{' '}
          </span>
          to formats like WebP. In this way, it will reduce image weight
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            without{' '}
          </span>
          sacrificing image quality.
        </h3>

        <ButtonText
          text="Discover Better Call Saul"
          class={classes.button}
          onClick={navigateHandler}
        />
      </div>
    </div>
  );
};

export default Optimization;
