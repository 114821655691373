import classes from './Image.module.css';

const Image = props => {
  return (
    <div className={`${classes.container} ${props.class} moveInBottomIntro`}>
      <img src={props.img} alt={props.alt} className={classes.img} />
      <div className={`${classes.circle}`}></div>
    </div>
  );
};

export default Image;
