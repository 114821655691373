import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';

import Essentials from './essentials/Essentials';
import Footer from './components/layouts/Footer';

import Home from './pages/Home';
import About from './pages/About';
import Practices from './pages/Practices';
import Works from './pages/Works';
import NewtralPage from './pages/individualWorks/NewtralPage';
import RentzPage from './pages/individualWorks/RentzPage';
import GorecipePage from './pages/individualWorks/GorecipePage';
import YourtripsPage from './pages/individualWorks/YourtripsPage';
import CashyPage from './pages/individualWorks/CashyPage';
import SaulPage from './pages/individualWorks/SaulPage';
import PaladisePage from './pages/individualWorks/PaladisePage';
import Contact from './pages/Contact';

const App = () => {
  // to connect the observer to showcase
  const location = useLocation();
  const headerHomeRef = useRef(null);
  const headerAboutRef = useRef(null);
  const headerPracticesRef = useRef(null);
  const headerWorksRef = useRef(null);
  const headerContactRef = useRef(null);

  const headerNewtralRef = useRef(null);
  const headerRentzRef = useRef(null);
  const headerGorecipeRef = useRef(null);
  const headerYourtripsRef = useRef(null);
  const headerCashyRef = useRef(null);
  const headerSaulRef = useRef(null);
  const headerPaladiseRef = useRef(null);

  const findingheaderActiveRef = () => {
    if (location.pathname === '/about') return headerAboutRef;
    if (location.pathname === '/practices') return headerPracticesRef;
    if (location.pathname === '/works') return headerWorksRef;
    if (location.pathname === '/contact') return headerContactRef;
    if (location.pathname === '/works/newtral') return headerNewtralRef;
    if (location.pathname === '/works/rentz') return headerRentzRef;
    if (location.pathname === '/works/gorecipe') return headerGorecipeRef;
    if (location.pathname === '/works/yourtrips') return headerYourtripsRef;
    if (location.pathname === '/works/cashy') return headerCashyRef;
    if (location.pathname === '/works/saul') return headerSaulRef;
    if (location.pathname === '/works/paladise') return headerPaladiseRef;

    if (location.pathname === '/') return headerHomeRef;
  };

  const headerActiveRef = findingheaderActiveRef();

  // spinner
  const [isLoading, setLoading] = useState(true);

  const fakeRequest = () => {
    return new Promise(resolve => setTimeout(() => resolve(), 3500));
  };

  useEffect(() => {
    fakeRequest().then(() => {
      const spinner = document.querySelector('.spinner');

      if (spinner) {
        spinner.remove();
        setLoading(!isLoading);
      }
    });
  }, [isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="container-main">
      {/* Navs */}
      <Essentials headerRef={headerActiveRef} />

      {/* Routes */}
      <Routes>
        <Route path="/" element={<Home ref={headerHomeRef} />} />
        <Route path="/about" element={<About ref={headerAboutRef} />} />
        <Route
          path="/practices"
          element={<Practices ref={headerPracticesRef} />}
        />
        <Route path="/works" element={<Works ref={headerWorksRef} />} />
        <Route
          path="/works/newtral"
          element={<NewtralPage ref={headerNewtralRef} />}
        />
        <Route
          path="/works/rentz"
          element={<RentzPage ref={headerRentzRef} />}
        />
        <Route
          path="/works/gorecipe"
          element={<GorecipePage ref={headerGorecipeRef} />}
        />
        <Route
          path="/works/yourtrips"
          element={<YourtripsPage ref={headerYourtripsRef} />}
        />
        <Route
          path="/works/cashy"
          element={<CashyPage ref={headerCashyRef} />}
        />
        <Route path="/works/saul" element={<SaulPage ref={headerSaulRef} />} />
        <Route
          path="/works/paladise"
          element={<PaladisePage ref={headerPaladiseRef} />}
        />
        <Route path="/contact" element={<Contact ref={headerContactRef} />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default App;
