import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import classes from './PracticesContent.module.css';
import { scrollIntoPosition } from '../helpers/helpers';

import Video from '../frames/Video';
import NumberedItem from '../layouts/NumberedItem';
import ButtonText from '../buttons/ButtonText';

import videoWebm from './../../assets/vid/practices/practices-interface.webm';

const UserInterface = props => {
  // Navigation
  const [toBrandPage, setBrandPage] = useState(false);

  if (toBrandPage === true) return <Navigate to="/works/newtral" />;

  const navigateHandler = () => {
    setBrandPage(true);
    scrollIntoPosition('top');
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <NumberedItem
        number="3"
        title="User Interface Principle"
        class={classes.heading}
      />
      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Designing a visually - engaging
          <span className={classes.highlightTextHeading}> consumer </span>
          journey.
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          Whether light or dark
          <span className={`${classes.highlightText} heading-2`}> palette</span>
          , I ensure that through your website, your target
          <span className={`${classes.highlightText} heading-2`}> market </span>
          will get hooked. I manipulate visual
          <span className={`${classes.highlightText} heading-2`}> paths </span>
          to make them clearly see what your products are through your brand
          <span className={`${classes.highlightText} heading-2`}> story </span>.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          From this way, it can turn them into
          <span className={`${classes.highlightText} heading-2`}> buyers </span>
          as it facilitates
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            interactions{' '}
          </span>
          between the user and your website. This involves managing colors,
          typography, spacing,
          <span className={`${classes.highlightText} heading-2`}> focal </span>
          point, etc.
        </h3>

        <ButtonText
          text="Discover Newtral"
          class={classes.button}
          onClick={navigateHandler}
        />
      </div>
    </div>
  );
};

export default UserInterface;
