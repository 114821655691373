import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import classes from './WorksContent.module.css';
import { scrollIntoPosition } from '../helpers/helpers';

import Video from '../frames/Video';
import NumberedItem from '../layouts/NumberedItem';
import ButtonText from '../buttons/ButtonText';

import videoWebm from './../../assets/vid/works/works-gorecipe.webm';

const Gorecipe = props => {
  // Navigation
  const [toBrandPage, setBrandPage] = useState(false);

  if (toBrandPage === true) return <Navigate to="/works/gorecipe" />;

  const navigateHandler = () => {
    setBrandPage(true);
    scrollIntoPosition('top');
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <NumberedItem number="3" title="Gorecipe" class={classes.heading} />
      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          A cookbook application.
        </h2>

        <ul className={`${classes.contentBullet} heading-3`}>
          <li className={classes.bullet}>Search recipes</li>
          <li className={classes.bullet}>
            Increase and decrease the amount of servings
          </li>
          <li className={classes.bullet}>Bookmark recipes</li>
          <li className={classes.bullet}>
            Limit upload recipes to only twice a day
          </li>
          <li className={classes.bullet}>
            Save bookmarks and username to local storage
          </li>
          <li className={classes.bullet}>Pastel styling</li>
        </ul>

        <ButtonText
          text="Discover Gorecipe"
          class={classes.button}
          onClick={navigateHandler}
        />
      </div>
    </div>
  );
};

export default Gorecipe;
