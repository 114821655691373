import classes from './ButtonReg.module.css';

import { useElementOnScreenOnce } from '../helpers/hooks';

export const ButtonCTA = props => {
  // observer
  const [buttonRef, isButtonAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 1,
  });

  const classButtonRef = isButtonAppeared ? 'buttonCta' : 'hidden';

  return (
    <button
      className={`${classes.btnCTA} ${props.class} ${classButtonRef}`}
      onClick={props.onClick}
      ref={buttonRef}
    >
      {props.text}
    </button>
  );
};

export const ButtonGhost = props => {
  // observer
  const [buttonRef, isButtonAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 1,
  });

  const classButtonRef = isButtonAppeared ? 'buttonGhost' : 'hidden';

  return (
    <button
      className={`${classes.btnGhost} ${props.class} ${classButtonRef}`}
      onClick={props.onClick}
      ref={buttonRef}
    >
      {props.text}
    </button>
  );
};
