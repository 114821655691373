import classes from './VerticalAdjaText.module.css';

const VerticalAdjaText = props => {
  const noMoveInRight = props.animation;

  return (
    <div
      className={`${classes.container} ${props.class} ${
        noMoveInRight ? '' : 'moveInRight'
      }`}
    >
      <span className={`${classes.small} ${props.classTextSmall}`}>
        {props.small}{' '}
      </span>
      <span className={`${classes.large} ${props.classText} heading-display`}>
        {props.large}
      </span>
    </div>
  );
};

export default VerticalAdjaText;
