import classes from './AboutContent.module.css';

import { useElementOnScreenOnce } from '../helpers/hooks';

import img from './../../assets/img/about/about-timeline.png';

const Timeline = props => {
  // observer
  const [imageRef, isImageAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 0.15,
  });

  const [elementRef, isElementAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 0.15,
  });

  const classImageRef = isImageAppeared ? 'moveInBottom' : 'hidden';
  const classHeadingRef = isElementAppeared ? 'moveInRight' : 'hidden';
  const classParagraphRef = isElementAppeared ? 'textClip' : 'hidden';

  return (
    <div className={`${classes.container} ${props.class}`}>
      <div className={`${classes.imgBox} ${classImageRef}`}>
        <img src={img} alt="guitar" className={classes.img} ref={imageRef} />
      </div>

      <div className={classes.contentBox}>
        <h2 className={`${classes.heading} ${classHeadingRef} heading-2`}>
          How long did it take?
        </h2>
        <h3
          className={`${classes.paragraph} ${classes.paragraph1} ${classParagraphRef} heading-3`}
          ref={elementRef}
        >
          I'm a
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            calendar{' '}
          </span>
          person who marks a date on finishing a task. So far, this system
          worked well for me because this
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            adaption{' '}
          </span>
          to web development really went on my planned
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            timeline
          </span>
          .
        </h3>

        <h3
          className={`${classes.paragraph} ${classes.paragraph2} ${classParagraphRef} heading-3`}
        >
          I've spent 3 months
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            studying{' '}
          </span>
          the fundamentals; 2 months
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            building{' '}
          </span>
          websites; 3 weeks
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            enhancing{' '}
          </span>
          my skills through back-end; and 1 week
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            preparing{' '}
          </span>
          a job hunt.
        </h3>

        <h3
          className={`${classes.paragraph} ${classes.paragraph3} ${classParagraphRef} heading-3`}
        >
          Setting a
          <span className={`${classes.highlightText} heading-2`}> goal </span>
          that in the future, I'm the developer that business owners will look
          for and
          <span className={`${classes.highlightText} heading-2`}> rely </span>
          on --- if they wanted to make their brands
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            special{' '}
          </span>
          .
        </h3>
      </div>
    </div>
  );
};

export default Timeline;
