import { Navigate } from 'react-router-dom';
import React, { useState } from 'react';

import NumberedItem from '../../components/layouts/NumberedItem';
import Image from '../../components/frames/Image';
import Video from '../../components/frames/Video';
import Ripple from '../../components/floaters/Ripple';
import ButtonBox from './../../components/buttons/ButtonBox';
import ButtonText from '../../components/buttons/ButtonText';

import classes from './IndividualWorks.module.css';
import { scrollIntoPosition } from '../../components/helpers/helpers';

import brandImg from './../../assets/img/logos/logo-yourtrips.png';
import videoWebm from './../../assets/vid/responsive/responsive-yourtrips.webm';

const YourtripsPage = (props, ref) => {
  // Navigation
  const [toGoBackPage, setGoBackPage] = useState(false);

  if (toGoBackPage === true) return <Navigate to="/works" />;

  const navigateHandler = () => {
    setGoBackPage(true);
    scrollIntoPosition('top');
  };

  const clickCtaHandler = () => {
    window.location.href = 'https://yourtrips.netlify.app';
  };

  const clickGhostHandler = () => {
    window.location.href = 'https://github.com/kershey28';
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <Ripple />
      <NumberedItem
        number="4"
        title="Your Handy To-go List"
        class={classes.heading}
        ref={ref}
      />

      <Image img={brandImg} class={classes.brandImg} />

      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Planning trips by pinning on a map.
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          A
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            vanilla{' '}
          </span>
          JavaScript application that interacts with the
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            Leaflet{' '}
          </span>
          library and display map. This is built with HTML and
          <span className={`${classes.highlightText} heading-2`}> SCSS</span>.
          Its structure is built with
          <span className={`${classes.highlightText} heading-2`}> OOP </span>
          using classes and objects.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          Nowadays, our friends draw a lot of plans and
          <span className={`${classes.highlightText} heading-2`}> trips</span>,
          right? That's why I came up with this app that will let the user take
          down its plans through a
          <span className={`${classes.highlightText} heading-2`}> map</span>.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph3} heading-3`}
        >
          This is the first app that I used an
          <span className={`${classes.highlightText} heading-2`}> API </span>
          and getting the username to
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            personalize{' '}
          </span>
          the UX.
        </h3>

        <ButtonText
          text="Check other brands"
          onClick={navigateHandler}
          class={classes.buttonBack}
        />

        <ButtonBox
          cta="Discover live"
          ghost="Go to repository"
          class={classes.button}
          onClickCta={clickCtaHandler}
          onClickGhost={clickGhostHandler}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(YourtripsPage);
