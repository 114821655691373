import { Navigate } from 'react-router-dom';
import React, { useState } from 'react';

import NumberedItem from '../../components/layouts/NumberedItem';
import Image from '../../components/frames/Image';
import Video from '../../components/frames/Video';
import Ripple from '../../components/floaters/Ripple';
import ButtonBox from './../../components/buttons/ButtonBox';
import ButtonText from '../../components/buttons/ButtonText';

import classes from './IndividualWorks.module.css';
import { scrollIntoPosition } from '../../components/helpers/helpers';

import brandImg from './../../assets/img/logos/logo-saul.png';
import videoWebm from './../../assets/vid/responsive/responsive-saul.webm';

const SaulPage = (props, ref) => {
  // Navigation
  const [toGoBackPage, setGoBackPage] = useState(false);

  if (toGoBackPage === true) return <Navigate to="/works" />;

  const navigateHandler = () => {
    setGoBackPage(true);
    scrollIntoPosition('top');
  };

  const clickCtaHandler = () => {
    window.location.href = 'https://callsaul.netlify.app';
  };

  const clickGhostHandler = () => {
    window.location.href = 'https://github.com/kershey28';
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <Ripple />
      <NumberedItem
        number="6"
        title="Your Speedy Legal Hotline"
        class={classes.heading}
        ref={ref}
      />

      <Image img={brandImg} class={classes.brandImg} />

      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          Serving justice to all law-breakers :)
        </h2>
        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph1} heading-3`}
        >
          A
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            vanilla{' '}
          </span>
          JavaScript website that tributes Saul Goodman from
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            Breaking Bad
          </span>
          . This is built with HTML and
          <span className={`${classes.highlightText} heading-2`}> SCSS</span>.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph2} heading-3`}
        >
          The reason I came up with this project is because while I was looking
          for my
          <span className={`${classes.highlightText} heading-2`}> second </span>
          project to build, I was binging
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            Better Call Saul{' '}
          </span>
          series --- taking its final season. What really struggled me here is
          the text content to fill. Given that I don't have any idea on
          <span className={`${classes.highlightText} heading-2`}> legal </span>
          matters.
        </h3>

        <h3
          className={`${classes.contentParagraph} ${classes.contentParagraph3} heading-3`}
        >
          Nevertheless, I
          <span className={`${classes.highlightText} heading-2`}> stick </span>
          to my style on not using
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            placeholders{' '}
          </span>
          --- lorems. Therefore, I did it in a Saul way. And yes, this was
          <span className={`${classes.highlightText} heading-2`}> fun</span>.
        </h3>

        <ButtonText
          text="Check other brands"
          onClick={navigateHandler}
          class={classes.buttonBack}
        />

        <ButtonBox
          cta="Discover live"
          ghost="Go to repository"
          class={classes.button}
          onClickCta={clickCtaHandler}
          onClickGhost={clickGhostHandler}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(SaulPage);
