import VerticalAdjaText from '../typography/VerticalAdjaText';

import classes from './Header.module.css';
import React from 'react';

const Header = (props, ref) => {
  return (
    <div className={`${classes.container} ${props.class}`} ref={ref}>
      <h3 className={`${props.classText1} heading-2`}>
        {props.text1}
        <div className={classes.questionmark}>?</div>
      </h3>

      <div className={classes.textBoxOuter}>
        <div className={classes.textBoxInner}>
          <div className={classes.textBoxInner1}>
            <h1
              className={`${props.classTextLast} ${classes.text2} heading-display`}
            >
              {props.text2}
            </h1>
          </div>

          <div className={classes.textBoxInner2}>
            <VerticalAdjaText
              small={props.textMini}
              large={props.textLast}
              classText={`${props.classTextLast} heading-display`}
              className={classes.text3}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(Header);
