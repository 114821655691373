import classes from './AboutContent.module.css';

import { useElementOnScreenOnce } from '../helpers/hooks';

import img from './../../assets/img/about/about-leisure.png';

const Leisure = props => {
  // observer
  const [imageRef, isImageAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 0.15,
  });

  const [elementRef, isElementAppeared] = useElementOnScreenOnce({
    root: null,
    threshold: 0.15,
  });

  const classImageRef = isImageAppeared ? 'moveInBottom' : 'hidden';
  const classHeadingRef = isElementAppeared ? 'moveInRight' : 'hidden';
  const classParagraphRef = isElementAppeared ? 'textClip' : 'hidden';

  return (
    <div className={`${classes.container} ${props.class}`}>
      <div className={`${classes.imgBox} ${classImageRef}`}>
        <img src={img} alt="guitar" className={classes.img} ref={imageRef} />
      </div>

      <div className={classes.contentBox}>
        <h2 className={`${classes.heading} ${classHeadingRef} heading-2`}>
          What I do for leisure?
        </h2>
        <h3
          className={`${classes.paragraph} ${classes.paragraph1} ${classParagraphRef} heading-3`}
          ref={elementRef}
        >
          I cannot do websites without a
          <span className={`${classes.highlightText} heading-2`}> music </span>
          that makes my feet on a beat. So, music really contributes to my
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            productivity
          </span>
          .
        </h3>

        <h3
          className={`${classes.paragraph} ${classes.paragraph2} ${classParagraphRef} heading-3`}
        >
          I'm a music lover that
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            appreciates{' '}
          </span>
          any kind of genre. Whether you're a person that's into
          <span className={`${classes.highlightText} heading-2`}> rock </span>
          or hiphop, pop or
          <span className={`${classes.highlightText} heading-2`}> jazz</span>,
          k-pop or
          <span className={`${classes.highlightText} heading-2`}> anison</span>,
          I will sing along with you! Because the beat that matters is the one
          that comes from the
          <span className={`${classes.highlightText} heading-2`}> heart</span>.
        </h3>

        <h3
          className={`${classes.paragraph} ${classes.paragraph3} ${classParagraphRef} heading-3`}
        >
          Speaking of other hobbies, well... let me just share it to you
          <span className={`${classes.highlightText} heading-2`}>
            {' '}
            personally{' '}
          </span>{' '}
          :)
        </h3>
      </div>
    </div>
  );
};

export default Leisure;
