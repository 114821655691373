import { Navigate } from 'react-router-dom';
import { useState } from 'react';

import classes from './WorksContent.module.css';
import { scrollIntoPosition } from '../helpers/helpers';

import Video from '../frames/Video';
import NumberedItem from '../layouts/NumberedItem';
import ButtonText from '../buttons/ButtonText';

import videoWebm from './../../assets/vid/works/works-newtral.webm';

const Newtral = props => {
  // Navigation
  const [toBrandPage, setBrandPage] = useState(false);

  if (toBrandPage === true) return <Navigate to="/works/newtral" />;

  const navigateHandler = () => {
    setBrandPage(true);
    scrollIntoPosition('top');
  };

  return (
    <div className={`${classes.container} ${props.class}`}>
      <NumberedItem number="1" title="Newtral" class={classes.heading} />
      <Video webm={videoWebm} class={classes.video} />

      <div className={classes.contentBox}>
        <h2 className={`${classes.contentHeading} heading-2`}>
          A men's apparel shopping website
        </h2>

        <ul className={`${classes.contentBullet} heading-3`}>
          <li className={classes.bullet}>Add and remove items to cart</li>
          <li className={classes.bullet}>Disable selected items</li>
          <li className={classes.bullet}>Multi-pages routing</li>
          <li className={classes.bullet}>Direction aware carousel</li>
          <li className={classes.bullet}>Color emphasis styling</li>
        </ul>

        <ButtonText
          text="Discover Newtral"
          class={classes.button}
          onClick={navigateHandler}
        />
      </div>
    </div>
  );
};

export default Newtral;
