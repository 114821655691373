import classes from './Ripple.module.css';

const Ripple = props => {
  return (
    <div className={`${classes.container} ${props.class}`}>
      <div className={classes.circle}></div>
    </div>
  );
};

export default Ripple;
